.signup {
}
.sidedsign {
  background: #4152b3;
  width: 100%;
  height: 400px;

  clip-path: circle(63.4% at 43% 50%);
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.btnC {
  border: 1px solid #4152b3;
  background-color: #ffffff;
  color: #4152b3;
  padding: 2px 12px;
  border-radius: 5px;
}
.btnCA {
  background-color: #4152b3;
  border: 1px solid #4152b3;
  color: white;
  padding: 2px 12px;
  border-radius: 5px;
  /* transition: background-color 2s ease-in; */
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-70%, 0, 0);
    transform: translate3d(-70%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-70%, 0, 0);
    transform: translate3d(-70%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.mobile-mode {
  display: content;
}
.mobile-mode2 {
  display: content;
}

.openMobile {
  display: none;
}
@media screen and (max-width: 768px) {
  .mobile-mode > div:first-child {
    display: none;
  }
  .openMobile {
    display: block;
  }
  .mobile-mode2 {
    display: none;
  }
  .mobile-mode > div:nth-child(2) {
    padding: 14px;
    border-radius: 8px !important;
  }
}

.flist:hover {
  background-color: rgb(221, 221, 221);
  cursor: pointer;
}

.qbtn {
  cursor: pointer;
}
.qbtn:hover {
  background-color: rgb(221, 221, 221);
  cursor: pointer;
}
.searchInput{
  background-image: url('../src//asserts/search.svg');
  background-position: left;
  padding-left: 40px;
  background-repeat: no-repeat;
}